<template>
  <v-select
    v-bind="$attrs"
    label="Selecionar caixa"
    v-model="account_id"
    :items="accounts"
    item-text="name"
    item-value="id"
    return-object
    :disabled="hasOneAccount"
    outlined
    hide-details="auto"
  >
    <template v-slot:[`selection`]="{ item }">
        <AccountIcon :account="item" class="ml-1 mr-6"/>
        {{ item.name }}
        <small v-if="item.type == 'bank'" class="text--secondary ml-2">Ag: {{ item.bank_agency }} | CC: {{ item.bank_account }} </small>
    </template>
    <template v-slot:[`item`]="{ item }">
      <v-list-item-icon>
        <AccountIcon :account="item"/>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
           <small v-if="item.type == 'bank'" class="text--secondary">Ag: {{ item.bank_agency }} | CC: {{ item.bank_account }} </small>
        </v-list-item-title>
       
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>

import AccountIcon from "@/components/account/sections/AccountIcon"

export default {
  
  components : { AccountIcon },

  props: {
    value: {},

    exclude: {
      default: null,
    },

    allowedOnly: {
      default: false,
    },
  },

  data: () => ({
    account_id: null,
  }),

  computed: {
    accounts() {
      if (this.exclude) {
        return this.$store.state.accounts.accounts.filter(
          (row) => row.id !== this.exclude.id
        );
      }

      if (this.allowedOnly) {
        return this.$store.getters["accounts/allowedAccounts"];
      }

      return this.$store.state.accounts.accounts;
    },

    hasOneAccount() {
      return this.accounts.length == 1;
    },
  },

  watch: {
    value(val) {
      this.account_id = val;
      this.setDefaultAccount();
    },

    account_id(val) {
      this.$emit("input", this.account_id);
    },

    accounts: {
      deep: true,
      handler(newValue, oldValue) {
        this.setDefaultAccount();
      },
    },
  },

  created() {
    this.account_id = this.value;
    this.$store.dispatch("accounts/index");
    this.setDefaultAccount();
  },
  methods: {
    setDefaultAccount() {
      if (this.hasOneAccount) {
        this.account_id = this.accounts[0];
      }
    },
  },
};
</script>
