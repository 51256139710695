export default [
  // dashboard admin
  {
    path: '/dashboard/admin',
    name: 'adminDashboard',
    component: () => import('@/views/dashboard/AdminDashboard'),
    meta: { layout: 'appLayout', displayName: 'Admin', icon: 'mdi-finance', permission: 'adminDashboardIndex' },
  },
  {
    path: '/dashboard/vendedor',
    name: 'salesmanDashboard',
    component: () => import('@/views/dashboard/SalesmanDashboard'),
    meta: { layout: 'appLayout', displayName: 'Vendedor', icon: 'mdi-finance', },
  },
  {
    path: '/dashboard/vendas',
    name: 'SalesDashboard',
    component: () => import('@/views/dashboard/SalesDashboard'),
    meta: { layout: 'appLayout', displayName: 'SalesDashboard', icon: 'mdi-finance', permission: 'dashboardIndex' },
  },
  {
    path: '/dashboard/produtos',
    name: 'productsDashboard',
    component: () => import('@/views/dashboard/ProductsDashboard'),
    meta: { layout: 'appLayout', displayName: 'Produtos' },
  }
]